import React from 'react'
import { Provider } from 'react-redux'

import Routes from './routes'
import { store } from './store'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import 'leaflet/dist/leaflet.css'

const App = () => (
  <Provider store={store}>
    <Routes />
  </Provider>
)

export default App
