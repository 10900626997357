export const colorsRoute = [
  'blue',
  'indigo',
  'deepPurple',
  'purple',
  'lightBlue',
  'cyan',
  'teal',
  'green',
  'lightGreen'
]

export const colorsPlan = [
  'red',
  'pink',
  'yellow',
  'amber',
  'orange',
  'deepOrange',
  'lime'
]
