export const registerData = {
  name: '',
  cpf: '',
  email: '',
  document: '',
  phone_number: '',
  password: '',
  password2: '',
  district: '',
  street: '',
  number: '',
  cep: '',
  complement: ''
}
