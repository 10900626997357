import React from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'

import RecipeReviewCard from '../cards/pay'
import { mask } from '../../utils/mask'

const CarroselPayment = ({ data }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplaySpeed: 3000,
    cssEase: 'linear'
  }

  return (
    <Slider {...settings}>
      {data.map((element) => (
        <RecipeReviewCard
          key={element.uuid}
          title="Plano"
          subtitle={element.name}
          titlebody={mask('moeda', element.price)}
          body={element.coverage_area}
          description={element.description}
        />
      ))}
    </Slider>
  )
}

CarroselPayment.propTypes = {
  data: PropTypes.array.isRequired
}

export default CarroselPayment
