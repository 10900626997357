export const Container = {
  backgroundColor: '#0858dd',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  paddingBottom: '1rem'
}

export const Button = {
  color: '#FFFFFF',
  background: '#0858dd'
}

export const Box = {
  width: '70%',
  position: 'relative'
}

export const Img = {
  width: '100%',
  position: 'absolute',
  top: '0%'
}

export const Box1 = {
  display: 'flex',
  justifyContent: 'end'
}

export const Box2 = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  bgcolor: '#0858DD',
  color: '#FFFFFF'
}

export const Box3 = {
  padding: '2rem 1rem',
  bgcolor: '#FFFFFF',
  borderRadius: '30px 30px 15px 15px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly'
}

export const Box4 = {
  marginTop: '2rem',
  display: 'flex',
  flexDirection: 'column'
}

export const Dialog = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'space-evenly',
  height: '40vh',
  width: '75vw'
}
