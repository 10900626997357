import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import VisibilityIcon from '@mui/icons-material/Visibility'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { styled } from '@mui/material/styles'

import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './styles'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}))

const RecipeReviewCard = (props) => {
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <Card sx={{ width: '98%', borderRadius: '1rem' }}>
      <Box sx={styles.Box}>
        <Box sx={styles.SubBox}>
          <Typography variant="h5" component="div">
            {props.title}
          </Typography>
          <Typography variant="body1" component="div">
            {props.subtitle}
          </Typography>
        </Box>
        <VisibilityIcon sx={{ fontSize: 80, color: '#ffffff' }} />
      </Box>
      <CardContent>
        <Typography sx={{ color: '#000000' }} variant="h5" component="div">
          {props.titlebody}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {props.body}
        </Typography>
      </CardContent>
      <Container sx={styles.Container}>
        <Typography variant="body2" color="text.secondary">
          Ver mais
        </Typography>
        <ExpandMore
          sx={{ width: '100%' }}
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Container>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>Descrição:</Typography>
          <Typography sx={{ margin: 0 }} paragraph>
            {props.description}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  )
}

RecipeReviewCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  titlebody: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

export default RecipeReviewCard
