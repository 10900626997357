import { configureStore } from '@reduxjs/toolkit'
import mapReducer from './modules/map'

export const store = configureStore({
  reducer: {
    map: mapReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})
