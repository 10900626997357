export const Container = {
  display: 'flex',
  padding: 0
}

export const BoxL = {
  bgcolor: '#0858DD',
  height: '100vh',
  width: '45vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center'
}

export const BoxR = {
  bgcolor: '#FFFFFF',
  height: '100vh',
  width: '55vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  padding: '0px 15px'
}

export const Button = {
  color: '#FFFFFF',
  background: '#000000'
}

export const ImgOlho = {
  width: '90%'
}

export const ImgUser = {
  width: '70%'
}

export const Title = {
  padding: '12px',
  color: '#FFFFFF'
}

export const BoxText = {
  display: 'flex',
  flexDirection: 'column'
}

export const Text = {
  '&:hover': {
    cursor: 'pointer',
    fontWeight: 'bolder'
  }
}

export const Dialog = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'space-evenly',
  height: '40vh',
  width: '75vw'
}
