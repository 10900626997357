import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import AddIcCallIcon from '@mui/icons-material/AddIcCall'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import StoreMallDirectoryRoundedIcon from '@mui/icons-material/StoreMallDirectoryRounded'
import TimelapseIcon from '@mui/icons-material/Timelapse'

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { uniqueId } from 'lodash'
import {
  MapContainer,
  Marker,
  TileLayer
  // useMap
} from 'react-leaflet'
import useWebSocket, { ReadyState } from 'react-use-websocket'

import * as styles from './styles'

import SoundEffect from '../../assets/soundEffect.mp3'
import Header from '../../components/header'
import SimpleBottomNavigation from '../../components/BottomNavigate'
import * as Icon from '../../components/icon'
import api from '../../services/api'
import { usePosition } from '../../services/geolocation'
import { mask } from '../../utils/mask'

const Tracking = () => {
  const [open, setOpen] = React.useState(false)
  const [open1, setOpen1] = React.useState(false)
  const [open2, setOpen2] = React.useState(false)
  const [openCall, setOpenCall] = React.useState(false)
  const [cancelCall, setCancelCall] = React.useState(false)
  const [openFinish, setOpenFinish] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [type, setType] = React.useState(false) // false: escolta - true: ocorrencia
  const [call, setCall] = React.useState(null)
  const [vigilants, setVigilants] = React.useState(null)

  const [userLat, setUserLat] = React.useState(-16.06394)
  const [userLong, setUserLong] = React.useState(-57.67969)

  const [socketUrl, setSocketUrl] = React.useState(null)
  const [statusSocket, setStatusSocket] = React.useState(false)
  const [posHouse, setPosHouse] = React.useState([0, 0])
  const [posVigilants, setPosVigilantes] = React.useState([])

  const { latitude, longitude, err } = usePosition()
  const { lastMessage, readyState } = useWebSocket(socketUrl, {
    shouldReconnect: (_closeEvent) => true
  })

  const navigate = useNavigate()

  const song = new Audio(SoundEffect)

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated'
  }[readyState]

  // const ChangeView = () => {
  //   const map = useMap()
  //   map.setView([userLat, userLong])
  //   return null
  // }

  const filterVigilant = () => {
    const aux = vigilants.filter(element => element.uuid === call.vigilant_uuid)[0]
    return aux
  }

  const handleClickOpen = (type) => {
    setType(type)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCancel = async () => {
    const user = JSON.parse(localStorage.getItem('@OlhoVivo/login'))
    const role = localStorage.getItem('@OlhoVivo/user')

    if (role !== 'user') {
      navigate(`${process.env.PUBLIC_URL}/`)
      return
    }

    const config = {
      headers: {
        Authentication: user?.token || ''
      }
    }

    const sendData = {
      status: 'Chamada cancelada',
      finish_date: new Date()
    }

    await api.put(`/call/${call.uuid}`, sendData, config).then(() => {
      setOpenCall(false)
    })
  }

  const handleSubmit = async () => {
    if (!call) {
      setLoading(true)

      const user = JSON.parse(localStorage.getItem('@OlhoVivo/login'))
      const role = localStorage.getItem('@OlhoVivo/user')

      if (role !== 'user') {
        navigate(`${process.env.PUBLIC_URL}/`)
        return
      }

      const config = {
        headers: {
          Authentication: user?.token || ''
        }
      }

      let category = null
      await api.get('/call_category/all/', config).then((resp) => {
        category = resp.data
      }).catch((err) => {
        console.log(err)
        setLoading(false)
      })

      let sendData = {}

      if (type === true) {
        category = category.find(element => element.name === 'Ocorrencia')
        sendData = {
          user_uuid: user.user.uuid,
          category_uuid: category.uuid
        }
      } else {
        category = category.find(element => element.name === 'Escolta')
        sendData = {
          user_uuid: user.user.uuid,
          category_uuid: category.uuid,
          origin_point: JSON.stringify([userLat, userLong])
        }
      }

      await api.post('/call/', sendData, config).then((resp) => {
        localStorage.setItem('@OlhoVivo/userCall', resp.data.uuid)
        setLoading(false)
        setOpen(false)
        loadCall()
      }).catch((err) => {
        console.log(err)
        setLoading(false)
      })
    }
  }

  const handleFinishScoult = async () => {
    const user = JSON.parse(localStorage.getItem('@OlhoVivo/login'))
    const role = localStorage.getItem('@OlhoVivo/user')

    if (role !== 'user') {
      navigate(`${process.env.PUBLIC_URL}/`)
      return
    }

    const config = {
      headers: {
        Authentication: user?.token || ''
      }
    }

    const sendData = {
      user_concluded: true
    }

    await api.put(`/call/${call.uuid}`, sendData, config).then(() => {
      loadCall()
    }).catch((err) => {
      console.log(err)
    })

    setOpen1(false)
  }

  const loadCall = async () => {
    verifyFinish()
    const user = JSON.parse(localStorage.getItem('@OlhoVivo/login'))
    const role = localStorage.getItem('@OlhoVivo/user')

    if (role !== 'user') {
      navigate(`${process.env.PUBLIC_URL}/`)
      return
    }

    const config = {
      headers: {
        Authentication: user?.token || ''
      }
    }

    try {
      await api.get('/call/all/', config).then((resp) => {
        let tempCall = resp.data.find(
          element => element.user_uuid === user.user.uuid && element.status === 'Aguardando atendimento'
        )

        if (tempCall) {
          setCall(tempCall)
          setOpen1(false)
          setOpen2(false)
          setOpenCall(true)
          return
        }

        tempCall = resp.data.find(
          element => element.user_uuid === user.user.uuid && element.status === 'Atendimento em andamento'
        )

        if (tempCall) {
          setCall(tempCall)
          setOpen1(true)
          setOpenCall(false)
          if (tempCall.user_concluded === true) {
            setOpen2(true)
          }
        } else {
          setCall(null)
          setOpen1(false)
          setOpen2(false)
          setOpenCall(false)
        }
      }).catch((err) => {
        console.log(err)
      })
    } catch (err) {
      console.log(err)
    }
  }

  const loadVigilants = async () => {
    const user = JSON.parse(localStorage.getItem('@OlhoVivo/login'))
    const role = localStorage.getItem('@OlhoVivo/user')

    if (role !== 'user') {
      navigate(`${process.env.PUBLIC_URL}/`)
      return
    }

    const config = {
      headers: {
        Authentication: user?.token || ''
      }
    }

    await api.get('/vigilant/all/', config)
      .then((resp) => {
        setVigilants(resp.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const generateLinkWeb = (uuid) => {
    const aux = process.env.REACT_APP_API_URL.replace('http', 'ws')
    return `${aux}/websocket/vigilant/${uuid}`
  }

  const verifyFinish = async () => {
    const verifyUuid = localStorage.getItem('@OlhoVivo/userCall')
    if (verifyUuid) {
      const user = JSON.parse(localStorage.getItem('@OlhoVivo/login'))
      const role = localStorage.getItem('@OlhoVivo/user')

      if (role !== 'user') {
        navigate(`${process.env.PUBLIC_URL}/`)
        return
      }

      const config = {
        headers: {
          Authentication: user?.token || ''
        }
      }

      const resp = await api.get(`/call/${verifyUuid}`, config)
      if (resp.data.status === 'Chamada concluida') {
        setOpenFinish(true)
      } else if (resp.data.user_concluded === false && resp.data.vigilant_concluded === true) {
        song.play()
        setTimeout(() => {
          song.play()
        }, 4000)
        setTimeout(() => {
          song.play()
        }, 7000)
      }
    }
  }

  React.useEffect(() => {
    loadVigilants()
  }, [])

  React.useEffect(() => {
    loadCall()
    const timer = setInterval(() => loadCall(), 10000)
    return () => clearInterval(timer)
  }, [])

  React.useEffect(() => {
    if (lastMessage !== null) {
      const str = lastMessage.data.split(': ')
      const aux = JSON.parse(str[1])

      const tempPos = []
      let find = false
      if (posVigilants) {
        for (let i = 0; i < posVigilants.length; i++) {
          if (posVigilants[i].uuid === aux.uuid) {
            find = true
            tempPos.push(aux)
          } else {
            tempPos.push(posVigilants[i])
          }
        }
      }

      if (find === false) {
        tempPos.push(aux)
      }

      setPosVigilantes(tempPos)
    }
  }, [lastMessage])

  React.useEffect(() => {
    if (latitude && longitude) {
      setUserLat(latitude)
      setUserLong(longitude)
      if (err) {
        console.log(err)
      }
    }
  }, [latitude, longitude, err])

  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem('@OlhoVivo/login'))
    if (user.user.latitude && user.user.longitude) {
      setPosHouse([user.user.latitude, user.user.longitude])
    }
    setSocketUrl(generateLinkWeb(user.user.uuid))
  }, [])

  React.useEffect(() => {
    if (connectionStatus === 'Open') {
      setStatusSocket(true)
    } else {
      setStatusSocket(false)
    }
  }, [connectionStatus])

  return (
    <Container maxWidth="sm" sx={styles.Container}>
      <CssBaseline />
      <Header on={statusSocket} invisible={false} />
      <Box sx={styles.Box}>
        <MapContainer
          center={[userLat, userLong]}
          zoom={14}
          zoomControl={false}
          style={{ width: '100%', height: '100%' }}
        >
          {/* <ChangeView center={[userLat, userLong]} zoom={14} /> */}
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker position={[userLat, userLong]} icon={Icon.blackIcon} />
          <Marker position={posHouse} icon={Icon.redIcon} />
          {posVigilants.map((element) => (
            <Marker key={uniqueId()} position={element.coords} icon={Icon.motoIcon} />
          ))}
        </MapContainer>

        {open1 === false &&
        <Box sx={styles.MapBox}>
          <Button
            elevation={20}
            variant="contained"
            onClick={() => handleClickOpen(false)}
            startIcon={<AddIcCallIcon />}
          >
            Escolta
          </Button>
          <Button
            variant="contained"
            onClick={() => handleClickOpen(true)}
            startIcon={<StoreMallDirectoryRoundedIcon />}
          >
            ocorrencia
          </Button>
        </Box>}
      </Box>

      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        { loading
          ? <Box sx={styles.BoxDialog}>
              <TimelapseIcon sx={{ fontSize: 75 }} />
              <Typography variant="h4">Aguarde</Typography>
            </Box>
          : <Box sx={styles.BoxDialog}>
          {
            type === true
              ? <StoreMallDirectoryRoundedIcon fontSize="large" />
              : <AddIcCallIcon fontSize="large" />
          }
          <DialogTitle id="responsive-dialog-title">
            {type === true ? 'Solicitar Ocorrência' : 'Solicitar Escolta'}
          </DialogTitle>
          <DialogActions>
            <Button variant="contained" onClick={handleClose} autoFocus>
              Fechar
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Confirmar
            </Button>
          </DialogActions>
        </Box>
      }
      </Dialog>

      <Dialog
        open={openCall}
        aria-labelledby="responsive-dialog-title"
      >
        {cancelCall === false
          ? (
            <Box sx={styles.BoxDialog}>
              <TimelapseIcon sx={{ fontSize: 75 }} />
              <Typography variant="h6">Aguardando Atendimento</Typography>
              <Button
                size='large'
                variant="contained"
                onClick={() => setCancelCall(true)}
              >Cancelar</Button>
            </Box>
            )
          : (
            <Box sx={styles.BoxDialog}>
              <CancelIcon sx={{ fontSize: 75, color: 'red' }} />
              <Typography variant="h6">Cancelar Atendimento</Typography>
              <Stack direction="row" spacing={2}>
                <Button
                  size='large'
                  variant="contained"
                  onClick={() => setCancelCall(false)}
                >Voltar</Button>
                <Button
                  size='large'
                  variant="contained"
                  onClick={handleCancel}
                >Confirmar</Button>
              </Stack>
            </Box>
            )}
      </Dialog>

      {open1 && (
        <Box
          className="animate__animated animate__backInUp"
          sx={{ width: '100%', boxSizing: 'border-box' }}
        >
          <Box sx={styles.Box1}>
            DETALHES DO ATENDIMENTO
          </Box>
          <Box sx={styles.Box2}>
            <Box sx={styles.Box3}>
              <Typography variant="h6" display="block">
                {vigilants && call && mask('string', filterVigilant().name)}
              </Typography>
              <Typography
                sx={{ fontSize: '14px' }}
                variant="overline"
                display="block"
              >
                {vigilants && call && mask('telefone', filterVigilant().phone_number)}
              </Typography>
            </Box>
            <Box sx={styles.Box4}>
              <Button onClick={handleFinishScoult} variant="contained">
                FINALIZAR
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      <Dialog
        open={open2}
        aria-labelledby="responsive-dialog-title"
      >
        <Box sx={styles.BoxDialog}>
          <TimelapseIcon sx={{ fontSize: 75 }} />
          <Typography variant="h6">Aguardando Finalização por Parte do Vigilante</Typography>
        </Box>
      </Dialog>

      <Dialog
        open={openFinish}
        aria-labelledby="responsive-dialog-title"
      >
        <Box sx={styles.BoxDialog}>
          <CheckCircleIcon sx={{ color: 'green', fontSize: 75 }} />
          <Typography variant="h5">Chamada Finalizada</Typography>
        </Box>
        <DialogActions sx={{ justifyContent: 'center', marginBottom: '20px' }}>
          <Button onClick={() => {
            localStorage.removeItem('@OlhoVivo/userCall')
            setOpenFinish(false)
          }} variant="contained">
            Concluir
          </Button>
        </DialogActions>
      </Dialog>
      <SimpleBottomNavigation status={2} />
    </Container>
  )
}

export default Tracking
