import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../services/api'

const initialState = {
  loading: false,
  error: false,
  routes: [],
  plans: [],
  region: [],
  users: [],
  calls: [],
  category: []
}

export const routesAsync = createAsyncThunk('routes/getAsync', async () => {
  try {
    const user = JSON.parse(localStorage.getItem('@OlhoVivo/login'))
    const config = {
      headers: {
        Authentication: user?.token || ''
      }
    }

    const resp = await api.get('/route/all/', config)
    return resp.data
  } catch (err) {
    console.log(err)
  }
})

export const plansAsync = createAsyncThunk('plans/getAsync', async () => {
  try {
    const user = JSON.parse(localStorage.getItem('@OlhoVivo/login'))

    const config = {
      headers: {
        Authentication: user?.token || ''
      }
    }

    const resp = await api.get('/plan/all/', config)
    return resp.data
  } catch (err) {
    console.log(err)
  }
})

export const regionAsync = createAsyncThunk('region/getAsync', async () => {
  try {
    const user = JSON.parse(localStorage.getItem('@OlhoVivo/login'))

    const config = {
      headers: {
        Authentication: user?.token || ''
      }
    }

    const resp = await api.get('/route/all/', config)
    return resp.data
  } catch (err) {
    console.log(err)
  }
})

export const userAsync = createAsyncThunk('user/getAsync', async () => {
  try {
    const user = JSON.parse(localStorage.getItem('@OlhoVivo/login'))

    const config = {
      headers: {
        Authentication: user?.token || ''
      }
    }

    const resp = await api.get('/user/all/', config)
    return resp.data
  } catch (err) {
    console.log(err)
  }
})

export const callAsync = createAsyncThunk('call/getAsync', async () => {
  try {
    const user = JSON.parse(localStorage.getItem('@OlhoVivo/login'))

    const config = {
      headers: {
        Authentication: user?.token || ''
      }
    }

    const resp = await api.get('/call/all/', config)
    return resp.data
  } catch (err) {
    console.log(err)
  }
})

export const categoryAsync = createAsyncThunk('category/getAsync', async () => {
  try {
    const user = JSON.parse(localStorage.getItem('@OlhoVivo/login'))

    const config = {
      headers: {
        Authentication: user?.token || ''
      }
    }

    const resp = await api.get('/call_category/all/', config)
    return resp.data
  } catch (err) {
    console.log(err)
  }
})

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(plansAsync.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(plansAsync.fulfilled, (state, action) => {
        state.loading = false
        state.error = false
        state.plans = action.payload
      })
      .addCase(plansAsync.rejected, (state) => {
        state.loading = false
        state.error = true
      })
      .addCase(regionAsync.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(regionAsync.fulfilled, (state, action) => {
        state.loading = false
        state.error = false
        state.region = action.payload
      })
      .addCase(regionAsync.rejected, (state) => {
        state.loading = false
        state.error = true
      })
      .addCase(userAsync.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(userAsync.fulfilled, (state, action) => {
        state.loading = false
        state.error = false
        state.users = action.payload
      })
      .addCase(userAsync.rejected, (state) => {
        state.loading = false
        state.error = true
      })
      .addCase(callAsync.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(callAsync.fulfilled, (state, action) => {
        state.loading = false
        state.error = false
        state.calls = action.payload
      })
      .addCase(callAsync.rejected, (state) => {
        state.loading = false
        state.error = true
      })
      .addCase(categoryAsync.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(categoryAsync.fulfilled, (state, action) => {
        state.loading = false
        state.error = false
        state.category = action.payload
      })
      .addCase(categoryAsync.rejected, (state) => {
        state.loading = false
        state.error = true
      })
      .addCase(routesAsync.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(routesAsync.fulfilled, (state, action) => {
        state.loading = false
        state.error = false
        state.routes = action.payload
      })
      .addCase(routesAsync.rejected, (state) => {
        state.loading = false
        state.error = true
      })
  }
})

// export const {} = mapSlice.actions

export default mapSlice.reducer
