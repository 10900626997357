import L from 'leaflet'

import markerIconAmber from '../../assets/map-marker-amber.svg'
import markerIconBlack from '../../assets/map-marker-black.svg'
import markerIconDeepOrange from '../../assets/map-marker-deepOrange.svg'
import markerIconLime from '../../assets/map-marker-lime.svg'
import markerIconOrange from '../../assets/map-marker-orange.svg'
import markerIconRed from '../../assets/map-marker-red.svg'
import markerIconYellow from '../../assets/map-marker-yellow.svg'
import motoIconBlack from '../../assets/moto.svg'

const icon = logo =>
  new L.Icon({
    iconUrl: logo,
    iconRetinaUrl: logo,
    iconSize: new L.Point(24, 24)
  })

export const amberIcon = icon(markerIconAmber)
export const blackIcon = icon(markerIconBlack)
export const deepOrangeIcon = icon(markerIconDeepOrange)
export const limeIcon = icon(markerIconLime)
export const orangeIcon = icon(markerIconOrange)
export const yellowIcon = icon(markerIconYellow)
export const redIcon = icon(markerIconRed)
export const motoIcon = icon(motoIconBlack)
