export const Container = {
  display: 'flex',
  width: 'auto',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}

export const Box = {
  bgcolor: '#92BBFF',
  display: 'flex',
  alingItems: 'center',
  justifyContent: 'center'
}

export const SubBox = {
  display: 'flex',
  flexDirection: 'column',
  alingItems: 'center',
  justifyContent: 'center',
  color: '#ffffff'
}
