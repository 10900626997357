export const Container = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  padding: 0,
  flexDirection: 'column',
  justifyContent: 'space-between'
}

export const Box = {
  width: '100%',
  height: '100%',
  position: 'relative',
  zIndex: 1
}

export const MapBox = {
  display: 'flex',
  justifyContent: 'space-evenly',
  width: '100%',
  position: 'absolute',
  bottom: 30,
  zIndex: 500
}

export const BoxDialog = {
  padding: '1rem',
  display: 'flex',
  height: '40vh',
  width: '75vw',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly'
}

export const Box1 = {
  bgcolor: '#0858DD',
  color: '#FFFFFF',
  textAlign: 'center',
  padding: '.3rem'
}

export const Box2 = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box'
}

export const Box3 = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'start',
  width: '100%',
  marginLeft: '.5rem',
  borderRight: '1px solid #DCDCDC',
  boxSizing: 'border-box'
}

export const Box4 = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%'
}
