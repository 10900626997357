import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'

import AddIcCallIcon from '@mui/icons-material/AddIcCall'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import StoreMallDirectoryRoundedIcon from '@mui/icons-material/StoreMallDirectoryRounded'
import TimelapseIcon from '@mui/icons-material/Timelapse'

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  MapContainer,
  Marker,
  Polygon,
  TileLayer
  // useMap
} from 'react-leaflet'
import { useNavigate } from 'react-router-dom'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { uniqueId } from 'lodash'
import * as styles from './styles'

import SoundEffect from '../../assets/soundEffect.mp3'
import Header from '../../components/header'
// import SimpleBottomNavigation from '../../components/BottomNavigate'
import * as Icon from '../../components/icon'
import api from '../../services/api'
// import { usePosition } from '../../services/geolocation'
import { colorsRoute } from '../../utils/colors'
import { mask } from '../../utils/mask'
import * as Async from '../../store/modules/map'

const Map = () => {
  const [open, setOpen] = React.useState(false)
  const [open1, setOpen1] = React.useState(false)
  const [open2, setOpen2] = React.useState(false)
  const [openFinish, setOpenFinish] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [selectedUuid, setSelectedUuid] = React.useState(null)
  const [callUuid, setCallUuid] = React.useState(null)
  const [statusCall, setStatusCall] = React.useState(null)
  const [titleFooter, setTitleFooter] = React.useState('DETALHES')
  const [attendance, setAttendance] = React.useState(false)
  const [led, setLed] = React.useState(false)

  const [userLat, setUserLat] = React.useState(-16.06394)
  const [userLong, setUserLong] = React.useState(-57.67969)

  const [socketUrl, setSocketUrl] = React.useState(null)
  const [statusSocket, setStatusSocket] = React.useState(false)

  const [houses, setHouses] = React.useState([])
  // const [poly, setPoly] = React.useState([])
  const [filteredCalls, setFilteredCalls] = React.useState([])

  const routes = useSelector(state => state.map.routes)
  const calls = useSelector(state => state.map.calls)
  const clients = useSelector(state => state.map.users)
  const category = useSelector(state => state.map.category)
  // const region = useSelector(state => state.map.region)
  const plans = useSelector(state => state.map.plans)

  const dispatch = useDispatch()
  const { sendMessage, readyState } = useWebSocket(socketUrl, {
    shouldReconnect: (_closeEvent) => true
  })

  const navigate = useNavigate()

  const song = new Audio(SoundEffect)

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated'
  }[readyState]

  const colors = [
    Icon.amberIcon,
    Icon.deepOrangeIcon,
    Icon.limeIcon,
    Icon.redIcon,
    Icon.yellowIcon,
    Icon.orangeIcon
  ]

  // const ChangeView = () => {
  //   const map = useMap()
  //   map.setView([userLat, userLong])
  //   return null
  // }

  const filterColor = (index) => {
    if (index < colorsRoute.length) {
      return colorsRoute[index]
    } else {
      return colorsRoute[index % colorsRoute.length]
    }
  }

  const filterIcon = (uuid) => {
    for (let i = 0; i < plans.length; i++) {
      if (plans[i].uuid === uuid) {
        if (i < colors.length) {
          return colors[i]
        } else {
          return colors[i % colors.length]
        }
      }
    }
  }

  const filterUser = (uuid) => {
    const aux = houses.find(element => element.uuid === uuid)
    return aux
  }

  const filterCategory = (uuid) => {
    const aux = category.find(element => element.uuid === uuid)
    if (aux) {
      return aux
    }

    return ' '
  }

  // const filterHouse = (uuid) => {
  //   const aux = houses.find(element => element.uuid === uuid)
  //   if (aux) {
  //     return aux
  //   } else {
  //     return null
  //   }
  // }

  // const filterHouses = (array, region) => {
  //   const tempHouses = []
  //   for (let i = 0; i < array.length; i++) {
  //     if (array[i].plan_uuid && array[i].latitude && array[i].longitude) {
  //       let coordinates = null // latxA, LngyA, latxB, LngyB, Pxlat, Pylng
  //       let cross = 0

  //       for (let j = 0; j < region.length - 1; j++) {
  //         if (region[j][1] > region[j + 1][1]) {
  //           coordinates = [
  //             region[j + 1][0],
  //             region[j + 1][1],
  //             region[j][0],
  //             region[j][1],
  //             parseFloat(array[i].latitude),
  //             parseFloat(array[i].longitude)
  //           ]
  //         } else {
  //           coordinates = [
  //             region[j][0],
  //             region[j][1],
  //             region[j + 1][0],
  //             region[j + 1][1],
  //             parseFloat(array[i].latitude),
  //             parseFloat(array[i].longitude)
  //           ]
  //         }

  //         const r = (((coordinates[0] - coordinates[2]) * (coordinates[5] - coordinates[3])) - ((coordinates[4] - coordinates[2]) * (coordinates[1] - coordinates[3])))
  //         if (r > 0 && coordinates[1] < coordinates[5] && coordinates[5] <= coordinates[3]) {
  //           cross += 1
  //         }
  //       }

  //       if (cross % 2 === 1) {
  //         tempHouses.push(array[i])
  //       }
  //     }
  //   }

  //   setHouses(tempHouses)
  // }

  const handleSubmit = async () => {
    if (statusCall === 'Aguardando atendimento') {
      setLoading(true)
      const user = JSON.parse(localStorage.getItem('@OlhoVivo/login'))
      const role = localStorage.getItem('@OlhoVivo/user')

      if (role !== 'vigilant') {
        navigate(`${process.env.PUBLIC_URL}/`)
        return
      }

      const config = {
        headers: {
          Authentication: user?.token || ''
        }
      }

      const sendData = {
        vigilant_uuid: user.user.uuid,
        status: 'Atendimento em andamento'
      }

      await api.put(`/call/${callUuid}`, sendData, config)
        .then(() => {
          localStorage.setItem('@OlhoVivo/vigilantStatus', callUuid)
          setLoading(false)
          setOpen(false)
          loadCall()
        })
        .catch(() => {
          setLoading(false)
          setOpen(false)
        })
    } else {
      setLoading(true)
      const user = JSON.parse(localStorage.getItem('@OlhoVivo/login'))
      const role = localStorage.getItem('@OlhoVivo/user')

      if (role !== 'vigilant') {
        navigate(`${process.env.PUBLIC_URL}/`)
        return
      }

      const config = {
        headers: {
          Authentication: user?.token || ''
        }
      }

      const sendData = {
        vigilant_concluded: true
      }

      await api.put(`/call/${callUuid}`, sendData, config)
        .then(() => {
          setLoading(false)
          setOpen(false)
          loadCall()
        })
        .catch(() => {
          setLoading(false)
          setOpen(false)
        })
    }
  }

  const handleChange = (element) => {
    setStatusCall(element.status)
    setSelectedUuid(element.category_uuid)
    setCallUuid(element.uuid)
    setOpen(true)
  }

  const loadCall = async () => {
    dispatch(Async.callAsync())
  }

  const generateLinkWeb = (uuid) => {
    const aux = process.env.REACT_APP_API_URL.replace('http', 'ws')
    return `${aux}/websocket/vigilant/${uuid}`
  }

  const loadData = async () => {
    const user = JSON.parse(localStorage.getItem('@OlhoVivo/login'))
    const role = localStorage.getItem('@OlhoVivo/user')

    if (role !== 'vigilant') {
      navigate(`${process.env.PUBLIC_URL}/`)
      return
    }

    setSocketUrl(generateLinkWeb(user.user.uuid))

    dispatch(Async.plansAsync())
    dispatch(Async.regionAsync())
    dispatch(Async.userAsync())
    dispatch(Async.categoryAsync())
    dispatch(Async.routesAsync())
    loadCall()
  }

  const verifyFinish = async () => {
    const verifyUuid = localStorage.getItem('@OlhoVivo/vigilantStatus')
    if (verifyUuid) {
      const user = JSON.parse(localStorage.getItem('@OlhoVivo/login'))
      const role = localStorage.getItem('@OlhoVivo/user')

      if (role !== 'vigilant') {
        navigate(`${process.env.PUBLIC_URL}/`)
        return
      }

      const config = {
        headers: {
          Authentication: user?.token || ''
        }
      }

      const resp = await api.get(`/call/${verifyUuid}`, config)
      if (resp.data.status === 'Chamada concluida') {
        song.play()
        setOpenFinish(true)
      }
    }
  }

  const sendLocation = () => {
    if (navigator.geolocation) {
      console.log('aqui')
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude
        const longitude = position.coords.longitude
        setUserLat(latitude)
        setUserLong(longitude)

        const user = JSON.parse(localStorage.getItem('@OlhoVivo/login'))
        const role = localStorage.getItem('@OlhoVivo/user')

        if (role !== 'vigilant') {
          navigate(`${process.env.PUBLIC_URL}/`)
          return
        }

        sendMessage(JSON.stringify({
          uuid: user.user.uuid,
          coords: [latitude, longitude]
        }))
      })
    } else {
      const user = JSON.parse(localStorage.getItem('@OlhoVivo/login'))
      const role = localStorage.getItem('@OlhoVivo/user')

      if (role !== 'vigilant') {
        navigate(`${process.env.PUBLIC_URL}/`)
        return
      }

      sendMessage(JSON.stringify({
        uuid: user.user.uuid,
        coords: [-16.06394, -57.67969]
      }))
    }
  }

  // React.useEffect(() => {
  //   if (region.length > 0 && plans.length > 0) {
  //     const user = JSON.parse(localStorage.getItem('@OlhoVivo/login'))

  //     const userRegion = region.filter(element => element.uuid === user.user.route_uuid)[0]

  //     if (userRegion) {
  //       // setPoly(JSON.parse(userRegion.coordinates))
  //       filterHouses(clients, JSON.parse(userRegion.coordinates))
  //     }
  //   }
  // }, [region, plans, clients])

  React.useEffect(() => {
    if (clients.length > 0) {
      const tempHouses = []
      for (let i = 0; i < clients.length; i++) {
        if (clients[i].plan_uuid !== null && clients[i].latitude !== null && clients[i].longitude !== null) {
          tempHouses.push(clients[i])
        }
      }

      if (JSON.stringify(houses) !== JSON.stringify(tempHouses)) {
        setHouses(tempHouses)
      }
    }
  }, [clients, houses])

  React.useEffect(() => {
    if (calls.length > 0) {
      verifyFinish()
      setOpen2(false)
      setAttendance(false)
      const user = JSON.parse(localStorage.getItem('@OlhoVivo/login'))
      const role = localStorage.getItem('@OlhoVivo/user')

      if (role !== 'vigilant') {
        navigate(`${process.env.PUBLIC_URL}/`)
        return
      }

      let aux = []

      for (let i = 0; i < calls.length; i++) {
        if (calls[i].status === 'Atendimento em andamento' &&
          calls[i].vigilant_uuid === user.user.uuid) {
          aux = [calls[i]]

          const tempTitle = filterCategory(calls[i].category_uuid).name
          if (tempTitle === 'Escolta') {
            setTitleFooter('DETALHES DA ESCOLTA')
          } else if (tempTitle === 'Ocorrencia') {
            setTitleFooter('DETALHES DA OCORRENCIA')
          } else {
            setTitleFooter('DETALHES')
          }

          if (calls[i].vigilant_concluded === true) {
            setOpen2(true)
          }
          setAttendance(true)
          break
        } else if (calls[i].status === 'Aguardando atendimento') {
          setTitleFooter('DETALHES')
          aux.push(calls[i])
        }
      }

      if (aux.length > 0) {
        setOpen1(true)
      } else {
        setOpen1(false)
      }

      setFilteredCalls(aux)
    }
  }, [calls])

  // React.useEffect(() => {
  //   if (latitude && latitude !== null && longitude && longitude !== null) {
  //     setUserLat(latitude)
  //     setUserLong(longitude)

  //     const user = JSON.parse(localStorage.getItem('@OlhoVivo/login'))

  //     sendMessage(JSON.stringify({
  //       uuid: user.user.uuid,
  //       coords: [latitude, longitude]
  //     }))

  //     if (err) {
  //       console.log(err)
  //     }
  //   }
  // }, [latitude, longitude, err])

  React.useEffect(() => {
    loadData()
    const timerLocation = setInterval(() => sendLocation(), 5000)
    return () => clearInterval(timerLocation)
  }, [])

  React.useEffect(() => {
    loadData()
    const timer = setInterval(() => loadCall(), 10000)
    return () => clearInterval(timer)
  }, [])

  React.useEffect(() => {
    if (connectionStatus === 'Open') {
      setStatusSocket(true)
    } else {
      setStatusSocket(false)
    }
  }, [connectionStatus])

  React.useEffect(() => {
    const timer = setInterval(() => {
      setLed(true)
      setTimeout(() => setLed(false), 1000)
    }, 2000)
    return () => clearInterval(timer)
  }, [])

  return (
    <Container maxWidth="sm" sx={styles.Container}>
      <CssBaseline />
      <Header on={statusSocket} invisible={false} perfil={false} />
      <Box sx={styles.Box}>
        <MapContainer
          center={[userLat, userLong]}
          zoom={14}
          zoomControl={false}
          style={{ width: '100%', height: '100%' }}
        >
          {/* <ChangeView center={[userLat, userLong]} zoom={14} /> */}
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker position={[userLat, userLong]} icon={Icon.motoIcon} />
          {/* <Polygon pathOptions={{ color: 'purple' }} positions={poly} /> */}
          {routes.length > 0 && routes.map((element, index) =>
            <Polygon
              key={uniqueId()}
              pathOptions={{ color: filterColor(index) }}
              positions={JSON.parse(element.coordinates)}
            />
          )}
          {attendance === false
            ? houses.map((element) => (
              <Marker
                key={uniqueId()}
                position={[element.latitude, element.longitude]}
                icon={filterIcon(element.plan_uuid)}
              />
            ))
            : (<>
                {houses.map((element) => {
                  if (element.uuid === filteredCalls[0].user_uuid && led === true) {
                    return null
                  }

                  return (
                    <Marker
                      key={element.uuid}
                      position={[element.latitude, element.longitude]}
                      icon={filterIcon(element.plan_uuid)}
                    />
                  )
                })}

                {/* <Marker
                  position={[filterHouse(filteredCalls[0].user_uuid).latitude, filterHouse(filteredCalls[0].user_uuid).longitude]}
                  icon={filterIcon(filterHouse(filteredCalls[0].user_uuid).plan_uuid)}
                /> */}
                {filteredCalls[0]?.origin_point && (
                    <Marker
                      position={JSON.parse(filteredCalls[0].origin_point)}
                      icon={Icon.blackIcon}
                    />
                )}
              </>)
          }
        </MapContainer>
      </Box>

      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        { loading
          ? <Box sx={styles.BoxDialog}>
              <TimelapseIcon sx={{ fontSize: 75 }} />
              <Typography variant="h4">Aguarde</Typography>
            </Box>
          : <Box sx={styles.BoxDialog}>
          {
            filterCategory(selectedUuid).name === 'Ocorrencia'
              ? <StoreMallDirectoryRoundedIcon fontSize="large"sx={{ fontSize: 60 }} />
              : <AddIcCallIcon fontSize="large" sx={{ fontSize: 60 }} />
          }
          <Typography variant="h5">
            {statusCall === 'Aguardando atendimento'
              ? 'Aceitar'
              : 'Finalizar'} {filterCategory(selectedUuid).name}
          </Typography>
          <DialogActions>
            <Button variant="contained" onClick={() => setOpen(false)} autoFocus>
              Voltar
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Confirmar
            </Button>
          </DialogActions>
        </Box>
      }
      </Dialog>

      {open1 && filteredCalls.length > 0 && (
        <Box
          className="animate__animated animate__backInUp"
          sx={{ width: '100%', boxSizing: 'border-box' }}
        >
          <Box sx={styles.Box1}>
            {titleFooter}
          </Box>
          {houses.length > 0 && filteredCalls.map((element) => (
          <Box sx={styles.Box2} key={uniqueId()}>
            <Box sx={styles.Box3}>
              <Typography variant="h6" display="block">
                {element &&
                mask('string', filterUser(element?.user_uuid)?.name)}
              </Typography>
              <Typography
                sx={{ fontSize: '14px' }}
                variant="overline"
                display="block"
              >
                {element &&
                mask('telefone', filterUser(element?.user_uuid)?.phone_number)}
              </Typography>
            </Box>
            <Box sx={styles.Box4}>
              <Button onClick={() => handleChange(element)} variant="contained">
                {element.status === 'Aguardando atendimento' ? 'ACEITAR' : 'FINALIZAR'}
              </Button>
            </Box>
          </Box>
          ))}
        </Box>
      )}

      <Dialog
        open={open2}
        aria-labelledby="responsive-dialog-title"
      >
        <Box sx={styles.BoxDialog}>
          <TimelapseIcon sx={{ fontSize: 75 }} />
          <Typography variant="h6">Aguardando Finalização por Parte do Cliente</Typography>
        </Box>
      </Dialog>

      <Dialog
        open={openFinish}
        aria-labelledby="responsive-dialog-title"
      >
        <Box sx={styles.BoxDialog}>
          <CheckCircleIcon sx={{ color: 'green', fontSize: 75 }} />
          <Typography variant="h5">Chamada Finalizada</Typography>
        </Box>
        <DialogActions sx={{ justifyContent: 'center', marginBottom: '20px' }}>
          <Button onClick={() => {
            localStorage.removeItem('@OlhoVivo/vigilantStatus')
            setOpenFinish(false)
          }} variant="contained">
            Concluir
          </Button>
        </DialogActions>
      </Dialog>
      {/* <SimpleBottomNavigation status={2} /> */}
    </Container>
  )
}

export default Map
