import React from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'

import CardBasic from '../cards/carrosel'

const SimpleSlider = ({ data }) => {
  const settings = {
    dots: true,
    infinite: true,
    centerMode: true,
    centerPadding: '20px',
    lazyLoad: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    cssEase: 'linear'
  }

  const generateLink = (link) => {
    return `${process.env.REACT_APP_API_URL}/offer/${link}`
  }

  return (
    <Slider {...settings}>
      {data.map((element) => (
        <CardBasic
          key={element.uuid}
          link={generateLink(element.uuid)}
        />
      ))}
    </Slider>
  )
}

SimpleSlider.propTypes = {
  data: PropTypes.array.isRequired
}

export default SimpleSlider
