import React from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

// import Box from "@mui/material/Box";
import Avatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import Logout from '@mui/icons-material/Logout'
import PersonIcon from '@mui/icons-material/Person'

const AccountMenu = (props) => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Badge
            color={ props.on ? 'success' : 'error' }
            variant="dot"
            invisible={props.invisible}
          >
            <PersonIcon sx={{ color: '#ffffff', fontSize: 40 }} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            width: '150px',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {props.perfil === true &&
          <>
            <MenuItem onClick={() => navigate(process.env.PUBLIC_URL + '/usuario')}>
              <Avatar /> Perfil
            </MenuItem>
            <Divider />
          </>
        }

        <MenuItem
          onClick={() => {
            localStorage.removeItem('@OlhoVivo/login')
            localStorage.removeItem('@OlhoVivo/user')
            navigate(`${process.env.PUBLIC_URL}/`)
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Sair
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

AccountMenu.propTypes = {
  on: PropTypes.bool,
  invisible: PropTypes.bool,
  perfil: PropTypes.bool
}

AccountMenu.defaultProps = {
  on: true,
  invisible: true,
  perfil: true
}

export default AccountMenu
