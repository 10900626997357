export const Container = {
  display: 'flex',
  height: '100vh',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: 0
}

export const Box = {
  padding: '1rem 1rem',
  bgcolor: '#FFFFFF',
  borderRadius: '30px 30px 15px 15px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
}

export const BoxLabel = {
  display: 'flex',
  flexDirection: 'row'
}

export const BoxButton = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column'
}

export const Avatar = {
  width: 56,
  height: 56,
  marginBottom: '10px'
}
