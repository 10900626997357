import React from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import TimelapseIcon from '@mui/icons-material/Timelapse'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

import figura from '../../assets/figura1.svg'
import OlhoVivo from '../../assets/logo.svg'

import * as styles from './styles'

import api from '../../services/api'
import { validators } from '../../utils/validators'

const Forgotten = () => {
  const navigate = useNavigate()

  const [username, setUsername] = React.useState('')
  const [code, setCode] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [password2, setPassword2] = React.useState('')
  const [showPassword, setShowPassword] = React.useState(false)
  const [showPassword2, setShowPassword2] = React.useState(false)

  const [submit, setSubmit] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [token, setToken] = React.useState(null)
  const [menu, setMenu] = React.useState('Email')

  const handleSubmit = async () => {
    if (menu === 'Email') {
      setSubmit(true)

      if (username !== '') {
        setError(false)
        setLoading(true)

        await api.post('/login/forgot-my-password/', {
          email: username.trim().toLowerCase()
        }).then((resp) => {
          setToken(resp.data.token)
          setSubmit(false)
          setLoading(false)
          setMenu('Code')
        }).catch((err) => {
          console.log(err)
          setError(true)
          setLoading(false)
        })
      }
    } else if (menu === 'Code') {
      setSubmit(true)
      setError(false)
      setLoading(true)

      if (token) {
        const config = {
          headers: {
            Authentication: token
          }
        }

        await api.post(`/login/forgot-my-password/${code}`, {}, config)
          .then((resp) => {
            setToken(resp.data.token)
            setSubmit(false)
            setLoading(false)
            setMenu('Password')
          })
          .catch((err) => {
            console.log(err)
            setError(true)
            setLoading(false)
          })
      }
    } else {
      setSubmit(true)
      setError(false)
      setLoading(true)

      if (password === password2 && password !== '') {
        if (token) {
          const config = {
            headers: {
              Authentication: token
            }
          }

          await api.put('/login/new-password/', { password }, config)
            .then(() => {
              setSubmit(false)
              setLoading(false)
              navigate(process.env.PUBLIC_URL + '/login')
            })
            .catch((err) => {
              console.log(err)
              setError(true)
              setLoading(false)
            })
        }
      }
    }
  }

  const handleClose = () => {
    setOpen(false)
    navigate(process.env.PUBLIC_URL + '/login')
  }

  return (
    <Container maxWidth="sm" sx={styles.Container}>
      <CssBaseline />
      <Box sx={styles.BoxL}>
        <Typography
          sx={styles.Title}
          variant="h4"
          component="div"
        >
          Sua proteção dentro e fora de casa
        </Typography>
        <Box component="img" sx={styles.ImgUser} src={figura} alt="Usuario" />
      </Box>
      <Box sx={styles.BoxR}>
        <Box component="img" sx={styles.ImgOlho} src={OlhoVivo} alt="Visão" />
        <Stack direction="column" spacing={4}>
          {menu === 'Email'
            ? <TextField
                error={submit && validators('required', username).err}
                helperText={submit && validators('required', username).msg}
                autoFocus
                margin="dense"
                label="Email"
                type="email"
                fullWidth
                variant="standard"
                value={username}
                onChange={(e) => setUsername(e.target.value.toLowerCase())}
              />
            : menu === 'Code'
              ? <TextField
                error={submit && validators('required', code).err}
                helperText={submit && validators('required', code).msg}
                autoFocus
                margin="dense"
                label="Código"
                type="text"
                fullWidth
                variant="standard"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              : (
                <>
                  <TextField
                    error={submit && validators('required', password).err}
                    helperText={submit && validators('required', password).msg}
                    autoFocus
                    margin="dense"
                    label="Senha"
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    variant="standard"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <TextField
                    error={submit && password !== password2}
                    helperText={submit && password !== password2 && 'As senhas devem ser iguais'}
                    margin="dense"
                    label="Repita a Senha"
                    type={showPassword2 ? 'text' : 'password'}
                    fullWidth
                    variant="standard"
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword2(!showPassword2)} edge="end">
                            {showPassword2 ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </>
                )
          }
          <Button
            onClick={handleSubmit}
            className="animate__animated animate__fadeIn"
            size="large"
            sx={styles.Button}
            variant="contained"
            >
            {loading
              ? <CircularProgress size={25} sx={{ color: '#ffffff' }} />
              : 'Confirmar'
            }
          </Button>
          <Button
            onClick={() => navigate(process.env.PUBLIC_URL + '/login')}
            className="animate__animated animate__fadeIn"
            size="large"
            sx={styles.Button}
            variant="contained"
            >
            Cancelar
          </Button>
        </Stack>
      </Box>

      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        { loading
          ? (
          <Box sx={styles.Dialog}>
            <TimelapseIcon sx={{ fontSize: 75 }} />
            <Typography variant="h4">Aguarde</Typography>
          </Box>
            )
          : error
            ? (
          <Box sx={styles.Dialog}>
            <CancelIcon sx={{ fontSize: 75 }} color="error" />
            <Typography variant="h5">Verifique sua internet e tente novamente</Typography>
            <Button
              onClick={() => setOpen(false)}
              className="animate__animated animate__fadeIn"
              size="large"
              sx={styles.Button}
              variant="contained"
            >
              Confirmar
            </Button>
          </Box>
              )
            : (
          <Box sx={styles.Dialog}>
            <CheckCircleIcon sx={{ fontSize: 75 }} color="success" />
            <Typography variant="h6">Verifique Seu Email para Redefinir sua Senha</Typography>
            <Button
              onClick={handleClose}
              className="animate__animated animate__fadeIn"
              size="large"
              sx={styles.Button}
              variant="contained"
            >
              Confirmar
            </Button>
          </Box>
              )
        }
      </Dialog>
    </Container>
  )
}

export default Forgotten
