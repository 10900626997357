import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'

// import PersonIcon from '@mui/icons-material/Person'
import PlaceIcon from '@mui/icons-material/Place'
import CancelIcon from '@mui/icons-material/Cancel'

import React from 'react'
import { useNavigate } from 'react-router-dom'

// import { PromotionData } from './data'
import * as styles from './styles'

import Header from '../../components/header'
import SimpleBottomNavigation from '../../components/BottomNavigate'
import SimpleSlider from '../../components/carrosel'
import CarroselPayment from '../../components/carroselPayment'
import api from '../../services/api'

const Home = () => {
  const [PlanData, setPlanData] = React.useState([])
  const [client, setClient] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [offers, setOffers] = React.useState([])

  const navigate = useNavigate()

  const handleTracking = () => {
    if (client === true) {
      navigate(`${process.env.PUBLIC_URL}/rastreio`)
    } else {
      setOpen(true)
    }
  }

  const loadData = async () => {
    const user = JSON.parse(localStorage.getItem('@OlhoVivo/login'))
    const role = localStorage.getItem('@OlhoVivo/user')

    if (role !== 'user') {
      navigate(`${process.env.PUBLIC_URL}/`)
      return
    }

    if (user) {
      const config = {
        headers: {
          Authentication: user?.token || ''
        }
      }

      if (user.user.plan_uuid) {
        setClient(true)
      }

      try {
        await api.get('/offer/all/', config).then((resp) => {
          setOffers(resp.data)
        })

        await api.get('/plan/all/', config).then((resp) => {
          setPlanData(resp.data)
        })
      } catch (error) {
        console.log(error)
      }
    } else {
      navigate(`${process.env.PUBLIC_URL}/`)
    }
  }

  React.useEffect(() => {
    loadData()
  }, [])

  return (
    <Container maxWidth="sm" sx={styles.Container}>
      <CssBaseline />
      <Header />
      <Box sx={styles.Box}>
        <SimpleSlider data={offers} />

        <Box
          sx={{ margin: '2rem 1rem', width: '90%', bgcolor: '#AFAFAF', height: '1px' }}
        />
        <Box sx={styles.BoxApp}>
          <Button onClick={handleTracking}>
            <Box>
              <PlaceIcon sx={{ fontSize: 100 }} color="#4577F8" />
              <Typography
                variant="h6"
                sx={{ textAlign: 'center', fontWeight: '700' }}
                component="div"
              >
                RASTREAR
              </Typography>
            </Box>
          </Button>
        </Box>
        <Box
          sx={{ margin: '2rem 1rem', width: '90%', bgcolor: '#AFAFAF', height: '1px' }}
        />
        <Box sx={styles.Background}>
          <Box sx={styles.BoxPlan}>
            <Typography
              sx={{ color: 'rgba(66, 135, 249, 1)' }}
              variant="h6"
              component="div"
            >
              Nossos Planos
            </Typography>
            <Typography
              sx={{ color: 'rgba(166, 151, 138, 1)' }}
              variant="subtitle2"
              component="div"
            >
              Escolha o melhor plano pra você
            </Typography>
          </Box>
          <Box sx={styles.BoxSlide}>
            <CarroselPayment data={PlanData} />
          </Box>
        </Box>
      </Box>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <Box sx={styles.BoxDialog}>
          <CancelIcon sx={{ fontSize: 75, color: '#ff0000' }} />
          <Typography variant='h5'>Você Ainda não Possui Nenhum Plano!</Typography>
          <Button
            variant='contained'
            size='large'
            onClick={() => setOpen(false)}
          >Confirmar</Button>
        </Box>
      </Dialog>
      <SimpleBottomNavigation status={1} client={client} onClick={() => setOpen(true)} />
    </Container>
  )
}

export default Home
