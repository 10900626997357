import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Typography from '@mui/material/Typography'

import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork'
import PersonIcon from '@mui/icons-material/Person'
import PaymentsIcon from '@mui/icons-material/Payments'

import React from 'react'
import { useNavigate } from 'react-router-dom'

import { userData as user, planData as plan } from './data'
import * as styles from './styles'

import Header from '../../components/header'
import SimpleBottomNavigation from '../../components/BottomNavigate'
import api from '../../services/api'
import { mask } from '../../utils/mask'

const User = () => {
  const navigate = useNavigate()

  const [userData, setUserData] = React.useState(user)
  const [planData, setPlanData] = React.useState(plan)

  const loadData = async () => {
    let user = localStorage.getItem('@OlhoVivo/login')
    const role = localStorage.getItem('@OlhoVivo/user')

    if (role !== 'user') {
      navigate(`${process.env.PUBLIC_URL}/`)
      return
    }

    if (user) {
      user = JSON.parse(user)

      const config = {
        headers: {
          Authentication: user.token
        }
      }

      setUserData(user.user)
      if (user.user.plan_uuid) {
        let plan = await (await api.get('/plan/all/', config)).data
        plan = plan.filter((element) => element.uuid === user.user.plan_uuid)[0]
        if (plan) {
          setPlanData(plan)
        }
      }
    }
  }

  React.useEffect(() => {
    loadData()
  }, [])

  return (
    <Container maxWidth="sm" sx={styles.Container}>
      <CssBaseline />
      <Header />
      <Box sx={styles.Box}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <ListItem sx={{ fontSize: '2rem', margin: '10px 0px' }} disablePadding>
            <ListItemIcon sx={{ minWidth: '30px', justifyContent: 'center' }}>
              <PersonIcon sx={{ fontSize: 30 }} color={'#000000'} />
            </ListItemIcon>
            <Typography sx={{ marginLeft: '5px' }} variant="h6" component="div">
              Dados de Usuário
            </Typography>
          </ListItem>

          <Box sx={styles.BoxLabel}>
            <Typography variant="h7" component="div">Nome:</Typography>
            <Typography sx={{ marginLeft: '5px', color: 'gray' }} variant="h7" component="div">
              {userData.name}
            </Typography>
          </Box>

          {/* <Box sx={styles.BoxLabel}>
            <Typography variant="h7" component="div">Email:</Typography>
            <Typography sx={{ marginLeft: '5px', color: 'gray' }} variant="h7" component="div">
              {userData.email}
            </Typography>
          </Box> */}

          <Box sx={styles.BoxLabel}>
            <Typography variant="h7" component="div">CPF:</Typography>
            <Typography sx={{ marginLeft: '5px', color: 'gray' }} variant="h7" component="div">
              {mask('cpf', userData.cpf)}
            </Typography>
          </Box>

          <Box sx={styles.BoxLabel}>
            <Typography variant="h7" component="div">RG:</Typography>
            <Typography sx={{ marginLeft: '5px', color: 'gray' }} variant="h7" component="div">
              {userData.document}
            </Typography>
          </Box>

          <Box sx={styles.BoxLabel}>
            <Typography variant="h7" component="div">Telefone:</Typography>
            <Typography sx={{ marginLeft: '5px', color: 'gray' }} variant="h7" component="div">
              {mask('telefone', userData.phone_number)}
            </Typography>
          </Box>

          <ListItem sx={{ fontSize: '2rem', margin: '15px 0px' }} disablePadding>
            <ListItemIcon sx={{ minWidth: '30px', justifyContent: 'center' }}>
              <MapsHomeWorkIcon sx={{ fontSize: 30 }} color={'#000000'} />
            </ListItemIcon>
            <Typography sx={{ marginLeft: '5px' }} variant="h6" component="div">
              Dados da Residência
            </Typography>
          </ListItem>

          <Box sx={styles.BoxLabel}>
            <Typography variant="h7" component="div">Cep:</Typography>
            <Typography sx={{ marginLeft: '5px', color: 'gray' }} variant="h7" component="div">
              {mask('cep', userData.cep)}
            </Typography>
          </Box>

          <Box sx={styles.BoxLabel}>
            <Typography variant="h7" component="div">Bairro:</Typography>
            <Typography sx={{ marginLeft: '5px', color: 'gray' }} variant="h7" component="div">
              {userData.district}
            </Typography>
          </Box>

          <Box sx={styles.BoxLabel}>
            <Typography variant="h7" component="div">Rua:</Typography>
            <Typography sx={{ marginLeft: '5px', color: 'gray' }} variant="h7" component="div">
              {userData.street}
            </Typography>
          </Box>

          <Box sx={styles.BoxLabel}>
            <Typography variant="h7" component="div">Número:</Typography>
            <Typography sx={{ marginLeft: '5px', color: 'gray' }} variant="h7" component="div">
              {userData.number}
            </Typography>
          </Box>

          <Box sx={styles.BoxLabel}>
            <Typography variant="h7" component="div">Complemento:</Typography>
            <Typography sx={{ marginLeft: '5px', color: 'gray' }} variant="h7" component="div">
              {userData.complement}
            </Typography>
          </Box>

          <ListItem sx={{ fontSize: '2rem', margin: '15px 0px' }} disablePadding>
            <ListItemIcon sx={{ minWidth: '30px', justifyContent: 'center' }}>
              <PaymentsIcon sx={{ fontSize: 30 }} color={'#000000'} />
            </ListItemIcon>
            <Typography sx={{ marginLeft: '5px' }} variant="h6" component="div">
              Dados do Plano Contratado
            </Typography>
          </ListItem>

          {userData.plan_uuid
            ? (
            <>
              <Box sx={styles.BoxLabel}>
                <Typography variant="h7" component="div">Plano:</Typography>
                <Typography sx={{ marginLeft: '5px', color: 'gray' }} variant="h7" component="div">
                  {planData.name}
                </Typography>
              </Box>

              <Box sx={styles.BoxLabel}>
                <Typography variant="h7" component="div">Cobertura:</Typography>
                <Typography sx={{ marginLeft: '5px', color: 'gray' }} variant="h7" component="div">
                  {planData.coverage_area}
                </Typography>
              </Box>

              <Box sx={styles.BoxLabel}>
                <Typography variant="h7" component="div">Descrição:</Typography>
                <Typography sx={{ marginLeft: '5px', color: 'gray' }} variant="h7" component="div">
                  {planData.description}
                </Typography>
              </Box>
            </>
              )
            : (
            <Typography variant="h7" component="div" sx={{ marginBottom: '25px' }}>
              Você ainda não aderiu a nenhum plano.
            </Typography>
              )}

        </Box>
        <Box sx={styles.BoxButton}>
          <Button
            sx={{ marginTop: '1rem' }}
            variant="contained"
            onClick={() => navigate(process.env.PUBLIC_URL + '/inicio')}
          >
            Voltar
          </Button>
        </Box>
      </Box>
      <SimpleBottomNavigation status={0} />
    </Container>
  )
}

export default User
