import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Dialog from '@mui/material/Dialog'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import CancelIcon from '@mui/icons-material/Cancel'
import TimelapseIcon from '@mui/icons-material/Timelapse'

import OlhoVivo from '../../assets/logo3.png'
import figura from '../../assets/figura4.svg'

import React from 'react'
import { useNavigate } from 'react-router-dom'

import * as styles from './styles'

import api from '../../services/api'
import { validators } from '../../utils/validators'

const Vigilant = () => {
  const navigate = useNavigate()

  const [submit, setSubmit] = React.useState(false)
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [visible, setVisible] = React.useState(true)
  const [msg, setMsg] = React.useState('')

  const handleSubmit = async () => {
    setSubmit(true)

    if (username !== '' && password !== '') {
      setError(false)
      setLoading(true)
      setOpen(true)

      await api.post('/login/vigilant/', {
        email: username.trim().toLowerCase(),
        password
      }).then((resp) => {
        setLoading(false)
        localStorage.setItem('@OlhoVivo/login', JSON.stringify(resp.data))
        localStorage.setItem('@OlhoVivo/user', 'vigilant')
        navigate(process.env.PUBLIC_URL + '/mapa')
      }).catch((err) => {
        console.log(err)
        if (err?.response?.data?.detail[0]?.msg) {
          if (err.response.data.detail[0].msg === 'value is not a valid email address') {
            setMsg('Formato de Email Invalido')
          } else {
            setMsg(err.response.data.detail[0].msg || 'Erro ao realizar a requisição')
          }
        } else {
          setMsg(err?.response?.data?.detail[0]?.msg || 'ERRO! Verifique a Internet e Tente Novamante.')
        }
        setError(true)
        setLoading(false)
      })
      // navigate(process.env.PUBLIC_URL + '/mapa')
    }
  }

  return (
    <Container maxWidth="sm" sx={styles.Container}>
      <CssBaseline />
      {visible && <Box sx={styles.Box}>
        <Box component="img" sx={styles.ImgOlho} src={figura} alt="Visão" />
      </Box>}
      <Box sx={styles.BoxL}>
        <Typography
          sx={styles.TitleL}
          variant="body1"
          component="div"
        >
          Sua proteção dentro e fora de casa
        </Typography>
      </Box>
      <Box sx={styles.BoxR}>
        <Box sx={styles.TitleR}>
          <Box component="img" sx={styles.ImgMoto} src={OlhoVivo} alt="Visão" />

          <Stack direction="column" spacing={4}>
          <TextField
            error={submit && validators('required', username).err}
            helperText={submit && validators('required', username).msg}
            autoFocus
            margin="dense"
            id="name"
            label="Usuario"
            type="email"
            fullWidth
            variant="standard"
            value={username}
            onChange={(e) => setUsername(e.target.value.toLowerCase())}
            onFocus={() => setVisible(false)}
            onBlur={() => setVisible(true)}
          />
          <TextField
            error={submit && validators('required', password).err}
            helperText={submit && validators('required', password).msg}
            fullWidth
            id="standard-basic"
            type="password"
            label="Senha"
            variant="standard"
            value={password}
            onChange={(e) => setPassword(e.target.value.toLowerCase())}
            onFocus={() => setVisible(false)}
            onBlur={() => setVisible(true)}
          />
          <Button
            onClick={handleSubmit}
            className="animate__animated animate__fadeIn"
            size="large"
            sx={styles.Button}
            variant="contained"
            >
            Confirmar
          </Button>
        </Stack>
        </Box>
      </Box>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        { loading
          ? <Box sx={styles.Dialog}>
              <TimelapseIcon sx={{ fontSize: 75 }} />
              <Typography variant="h4">Aguarde</Typography>
            </Box>
          : error
            ? <Box sx={styles.Dialog}>
              <CancelIcon sx={{ fontSize: 75 }} color="error" />
              <Typography variant="h5">{msg}</Typography>
              <Button
                onClick={() => setOpen(false)}
                className="animate__animated animate__fadeIn"
                size="large"
                sx={styles.Button}
                variant="contained"
              >
                Confirmar
              </Button>
            </Box>
            : <></>
        }
      </Dialog>
    </Container>
  )
}

export default Vigilant
