export const Container = {
  padding: 0,
  height: '100vh',
  display: 'flex'
}

export const ImgOlho = {
  width: '70%'
}

export const ImgMoto = {
  width: '90%'
}

export const Button = {
  color: '#FFFFFF',
  background: '#000000'
}

export const Box = {
  position: 'absolute',
  bottom: 0,
  width: '22rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

export const BoxL = {
  bgcolor: '#0858DD',
  height: '100vh',
  width: '45vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start'
}

export const BoxR = {
  bgcolor: '#FFFFFF',
  height: '100vh',
  width: '55vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',
  padding: '0px 15px'
}

export const TitleL = {
  color: '#FFFFFF',
  fontWeight: '500px',
  fontFamily: 'Roboto',
  fontSize: '2.15rem',
  padding: '12px',
  marginTop: '5rem'
}

export const TitleR = {
  height: '70vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center'
}

export const Dialog = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'space-evenly',
  height: '40vh',
  width: '75vw'
}
