export const userData = {
  name: 'Fulano',
  cpf: '11983617008',
  email: 'email@email.com',
  document: '418758608',
  phone_number: '65999999999',
  district: 'district',
  street: 'street',
  number: '95',
  cep: '78210298',
  complement: 'lado impar'
}

export const planData = {
  uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  name: 'string',
  price: 0,
  coverage_area: 'string',
  description: 'string'
}
