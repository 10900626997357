import React from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'

import Home from './pages/Home'
import Login from './pages/Login'
import LoginVigilante from './pages/LoginVigilant'
import Forgotten from './pages/ForgottenPassword'
import Map from './pages/Map'
import Tracking from './pages/Tracking'
import Register from './pages/Register'
import Splash from './pages/Splash'
import User from './pages/User'

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={process.env.PUBLIC_URL + '/'} element={<Splash />} />
        <Route path={process.env.PUBLIC_URL + '/login'} element={<Login />} />
        <Route path={process.env.PUBLIC_URL + '/recuperar'} element={<Forgotten />} />
        <Route path={process.env.PUBLIC_URL + '/cadastro'} element={<Register />} />
        <Route path={process.env.PUBLIC_URL + '/inicio'} element={<Home />} />
        <Route path={process.env.PUBLIC_URL + '/usuario'} element={<User />} />
        <Route path={process.env.PUBLIC_URL + '/rastreio'} element={<Tracking />} />
        <Route path={process.env.PUBLIC_URL + '/vigilante'} element={<LoginVigilante />} />
        <Route path={process.env.PUBLIC_URL + '/mapa'} element={<Map />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
