import Box from '@mui/material/Box'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'

import AccountBoxIcon from '@mui/icons-material/AccountBox'
import HomeIcon from '@mui/icons-material/Home'
import MapIcon from '@mui/icons-material/Map'

import * as React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

const SimpleBottomNavigation = (props) => {
  const navigate = useNavigate()

  return (
    <Box sx={{ width: '100%' }}>
      <BottomNavigation
        showLabels
        value={props.status}
      >
        <BottomNavigationAction
          onClick={() => {
            navigate(`${process.env.PUBLIC_URL}/usuario`)
          }}
          label="Perfil"
          icon={<AccountBoxIcon sx={{ fontSize: 40 }} />}
        />
        <BottomNavigationAction
          onClick={() => {
            navigate(`${process.env.PUBLIC_URL}/inicio`)
          }}
          label="Início"
          icon={<HomeIcon sx={{ fontSize: 40 }} />}
        />
        <BottomNavigationAction
          onClick={() => {
            if (props.client === true) {
              navigate(`${process.env.PUBLIC_URL}/rastreio`)
            } else {
              props.onClick()
            }
          }}
          label="Rastreio"
          icon={<MapIcon sx={{ fontSize: 40 }} />}
        />
      </BottomNavigation>
    </Box>
  )
}

SimpleBottomNavigation.propTypes = {
  status: PropTypes.number.isRequired,
  client: PropTypes.bool,
  onClick: PropTypes.func
}

SimpleBottomNavigation.defaultProps = {
  client: true,
  onClick: () => {}
}

export default SimpleBottomNavigation
