import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import KeyIcon from '@mui/icons-material/Key'
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork'
import PersonIcon from '@mui/icons-material/Person'
import TimelapseIcon from '@mui/icons-material/Timelapse'

import React from 'react'
import { useNavigate } from 'react-router-dom'

import { registerData } from './data'
import * as styles from './styles'

import Figura2 from '../../assets/figura2.svg'
import api from '../../services/api'
import { mask } from '../../utils/mask'
import { validators } from '../../utils/validators'

const Register = () => {
  const [submit, setSubmit] = React.useState(false)
  const [data, setData] = React.useState(registerData)
  const [open, setOpen] = React.useState(false)
  const [teste, setTeste] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [msg, setMsg] = React.useState('')

  const navigate = useNavigate()

  const handleInputChange = (name, newValue) => {
    setData({
      ...data,
      [name]: newValue
    })
  }

  const validate = (data) => {
    let resp = true
    for (const item in data) {
      if (data[item] === '') {
        resp = false
        break
      }
    }
    return resp
  }

  const handleSubmit = async () => {
    setSubmit(true)

    if (data.password === data.password2) {
      const val = validate(data)
      if (val === true) {
        let resp = data
        for (const item in data) {
          resp = {
            ...resp,
            [item]: data[item].trim().toLowerCase()
          }
        }

        setLoading(true)
        setOpen(true)

        try {
          let role = await (await api.get('/role/all/')).data
          role = role.filter(element => element.name === 'Usuario')[0]
          if (role === null) {
            return
          }

          const sendData = {
            login_info: {
              email: resp.email,
              password: resp.password,
              role_uuid: role.uuid
            },
            user_info: {
              name: resp.name,
              cpf: resp.cpf,
              document: resp.document,
              phone_number: resp.phone_number,
              district: resp.district,
              street: resp.street,
              number: resp.number,
              cep: resp.cep,
              complement: resp.complement
            }
          }

          await api.post('/user/', sendData).then(() => {
            setError(false)
            setLoading(false)
          }).catch((err) => {
            console.log(err)
            if (err?.response?.data?.detail[0]?.msg) {
              if (err.response.data.detail[0].msg === 'value is not a valid email address') {
                setMsg('Formato de Email Invalido')
              } else {
                setMsg(err.response.data.detail[0].msg)
              }
            } else {
              setMsg(err?.response?.data?.detail[0]?.msg || 'ERRO! Verifique a Internet e Tente Novamante.')
            }
            setError(true)
            setLoading(false)
          })
        } catch (err) {
          console.log(err)
          setError(true)
          setLoading(false)
        }
      }
    }
  }

  const handleClose = () => {
    setOpen(false)
    navigate(process.env.PUBLIC_URL + '/login')
  }

  React.useEffect(() => {
    if (data.cep.length === 8 && teste === false) {
      setTeste(true)
      api.get(`https://viacep.com.br/ws/${data.cep}/json/`).then((resp) => {
        if (!resp.data?.erro) {
          setData({
            ...data,
            district: resp.data.bairro,
            street: resp.data.logradouro,
            complement: resp.data.complemento
          })
        }
      })
    } else {
      if (data.cep.length < 8) {
        setTeste(false)
      }
    }
  }, [data])

  return (
    <Container maxWidth="sm" sx={styles.Container}>
      <Box sx={styles.Box1}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 1 }}
        >
          <Avatar sx={{ bgcolor: '#0858DD' }}>
            <PersonIcon />
          </Avatar>
        </IconButton>
      </Box>

      <Box sx={styles.Box2}>
        <Typography sx={{ marginLeft: '1rem' }} variant="h6" component="div">
          Insira Seus Dados Cadastrais
        </Typography>
        <Box sx={styles.Box}>
          <Box component="img" sx={styles.Img} src={Figura2} alt="figura2" />
        </Box>
      </Box>

      <Box sx={styles.Box3}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <ListItem sx={{ fontSize: '2rem' }} disablePadding>
            <ListItemIcon sx={{ minWidth: '30px', justifyContent: 'center' }}>
              <KeyIcon sx={{ fontSize: 22 }} color={'#000000'} />
            </ListItemIcon>
            <ListItemText primary="Dados de Login" />
          </ListItem>
          <Box>
            <TextField
              error={submit && validators('required', data.email).err}
              helperText={submit && validators('required', data.email).msg}
              margin="dense"
              fullWidth
              sx={{ padding: 0 }}
              label="Email"
              inputProps={{ maxLength: 150 }}
              variant="standard"
              value={data.email.toLowerCase()}
              onChange={(e) => {
                handleInputChange(
                  'email',
                  validators('required', e.target.value).value
                )
              }
              }
            />
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}>
            <TextField
              error={submit && validators('required', data.password).err}
              helperText={submit && validators('required', data.password).msg}
              margin="dense"
              fullWidth
              sx={{ padding: 0, width: '45%' }}
              inputProps={{ maxLength: 100 }}
              label="Senha"
              type="password"
              variant="standard"
              value={data.password}
              onChange={(e) => {
                handleInputChange(
                  'password',
                  validators('required', e.target.value).value
                )
              }
              }
            />

            <TextField
              error={submit && data.password !== data.password2}
              helperText={submit && data.password !== data.password2 && 'As senhas devem ser iguais'}
              margin="dense"
              fullWidth
              sx={{ padding: 0, width: '50%' }}
              inputProps={{ maxLength: 100 }}
              label="Repita a Senha"
              type="password"
              variant="standard"
              value={data.password2}
              onChange={(e) => {
                handleInputChange('password2', validators('required', e.target.value).value)
              }}
            />
          </Box>
        </Box>

        <Box sx={styles.Box4}>
          <ListItem sx={{ fontSize: '2rem' }} disablePadding>
            <ListItemIcon sx={{ minWidth: '30px', justifyContent: 'center' }}>
              <PersonIcon sx={{ fontSize: 22 }} color={'#000000'} />
            </ListItemIcon>
            <ListItemText primary="Dados do Usuário" />
          </ListItem>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <Box>
              <TextField
                error={submit && validators('required', data.name).err}
                helperText={submit && validators('required', data.name).msg}
                margin="dense"
                fullWidth
                sx={{ padding: 0 }}
                inputProps={{ maxLength: 150 }}
                label="Nome Completo"
                variant="standard"
                value={mask('string', data.name)}
                onChange={(e) => {
                  handleInputChange(
                    'name',
                    validators('required', e.target.value).value
                  )
                }
                }
              />
            </Box>
            <Box>
              <TextField
                error={submit && validators('phone', data.phone_number).err}
                helperText={submit && validators('phone', data.phone_number).msg}
                margin="dense"
                fullWidth
                sx={{ padding: 0 }}
                label="Celular"
                variant="standard"
                value={mask('telefone', data.phone_number)}
                onChange={(e) => {
                  handleInputChange('phone_number', validators('phone', e.target.value).value)
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <TextField
                error={submit && validators('cpf', data.cpf).err}
                helperText={submit && validators('cpf', data.cpf).msg}
                margin="dense"
                sx={{ padding: 0, width: '45%' }}
                label="CPF"
                variant="standard"
                value={mask('cpf', data.cpf)}
                onChange={(e) => {
                  handleInputChange('cpf', validators('number', e.target.value))
                }}
              />
              <TextField
                error={submit && validators('required', data.document).err}
                helperText={submit && validators('required', data.document).msg}
                margin="dense"
                sx={{ padding: 0, width: '50%' }}
                label="RG"
                variant="standard"
                inputProps={{ maxLength: 50 }}
                value={data.document}
                onChange={(e) => {
                  handleInputChange('document', validators('required', e.target.value).value)
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box sx={styles.Box4}>
          <ListItem sx={{ fontSize: '2rem' }} disablePadding>
            <ListItemIcon sx={{ minWidth: '30px', justifyContent: 'center' }}>
              <MapsHomeWorkIcon sx={{ fontSize: 22 }} color={'#000000'} />
            </ListItemIcon>
            <ListItemText primary="Dados da Residência" />
          </ListItem>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <TextField
              error={submit && validators('required', data.cep).err}
              helperText={submit && validators('required', data.cep).msg}
              margin="dense"
              sx={{ padding: 0, width: '45%' }}
              label="CEP"
              variant="standard"
              value={mask('cep', data.cep)}
              onChange={(e) => {
                handleInputChange('cep', validators('number', e.target.value))
              }
              }
            />
            <TextField
              error={submit && validators('required', data.district).err}
              helperText={submit && validators('required', data.district).msg}
              margin="dense"
              sx={{ padding: 0, width: '50%' }}
              inputProps={{ maxLength: 100 }}
              label="Bairro"
              variant="standard"
              value={mask('string', data.district)}
              onChange={(e) => { handleInputChange('district', e.target.value) }}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <TextField
              error={submit && validators('required', data.street).err}
              helperText={submit && validators('required', data.street).msg}
              margin="dense"
              sx={{ padding: 0, width: '65%' }}
              inputProps={{ maxLength: 100 }}
              label="Rua"
              variant="standard"
              value={mask('string', data.street)}
              onChange={(e) => { handleInputChange('street', e.target.value) }}
            />
            <TextField
              margin="dense"
              sx={{ padding: 0, width: '30%' }}
              inputProps={{ maxLength: 10 }}
              label="Número"
              type="number"
              variant="standard"
              value={data.number}
              onChange={(e) => { handleInputChange('number', e.target.value) }}
            />
          </Box>
        </Box>

        <Box>
          <TextField
            margin="dense"
            fullWidth
            sx={{ padding: 0 }}
            inputProps={{ maxLength: 150 }}
            label="Complemento ou Ponto de Referência"
            variant="standard"
            value={data.complement}
            onChange={(e) => { handleInputChange('complement', e.target.value) }}
          />
        </Box>

        <Box>
          <Box
            sx={{
              display: 'flex',
              marginTop: '2rem',
              justifyContent: 'center',
              flexDirection: 'column'
            }}
          >
            <Button
              onClick={handleSubmit}
              variant="contained"
            >
              Concluir
            </Button>
            <Button
              onClick={() => {
                navigate(`${process.env.PUBLIC_URL}/login`)
              }}
              variant="contained"
              sx={{ marginTop: '1rem', bgcolor: '#9e9e9e' }}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
      </Box>

      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        { loading
          ? (
          <Box sx={styles.Dialog}>
            <TimelapseIcon sx={{ fontSize: 75 }} />
            <Typography variant="h4">Aguarde</Typography>
          </Box>
            )
          : error
            ? (
          <Box sx={styles.Dialog}>
            <CancelIcon sx={{ fontSize: 75 }} color="error" />
            <Typography variant="h5">{msg}</Typography>
            <Button
              onClick={() => setOpen(false)}
              className="animate__animated animate__fadeIn"
              size="large"
              sx={styles.Button}
              variant="contained"
            >
              Confirmar
            </Button>
          </Box>
              )
            : (
          <Box sx={styles.Dialog}>
            <CheckCircleIcon sx={{ fontSize: 75 }} color="success" />
            <Typography variant="h6">Cadastro Realizado com Sucesso</Typography>
            <Button
              onClick={handleClose}
              className="animate__animated animate__fadeIn"
              size="large"
              sx={styles.Button}
              variant="contained"
            >
              Confirmar
            </Button>
          </Box>
              )
        }
      </Dialog>
      {/* <SimpleBottomNavigation status={0} /> */}
    </Container>
  )
}

export default Register
