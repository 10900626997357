import React from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import AccountBoxIcon from '@mui/icons-material/AccountBox'
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports'

import * as styles from './styles'

import OlhoVivo from '../../assets/logo3.png'
import figura from '../../assets/figura1.svg'

const Splash = () => {
  const navigate = useNavigate()

  React.useEffect(() => {
    const login = JSON.parse(localStorage.getItem('@OlhoVivo/login'))

    if (login) {
      if (login?.user?.cep) {
        navigate(process.env.PUBLIC_URL + '/inicio')
      } else if (login?.user?.license) {
        navigate(process.env.PUBLIC_URL + '/mapa')
      }
    }
  }, [])

  return (
    <Container maxWidth="sm" sx={styles.Container}>
      <CssBaseline />
      <Box sx={styles.BoxL}>
        <Typography
          sx={styles.Title}
          variant="h4"
          component="div"
        >
          Sua proteção dentro e fora de casa
        </Typography>
        <Box component="img" sx={styles.Img} src={figura} alt="Visão" />
      </Box>
      <Box sx={styles.BoxR}>
        <Box component="img" sx={styles.Img} src={OlhoVivo} alt="Visão" />
        <Stack direction="column" spacing={4}>
          <Button
            onClick={() => {
              navigate(`${process.env.PUBLIC_URL}/login`)
            }}
            className="animate__animated animate__fadeIn"
            size="large"
            sx={styles.Button}
            variant="contained"
            startIcon={<AccountBoxIcon />}
          >
            Usuário
          </Button>
          <Button
            onClick={() => {
              navigate(`${process.env.PUBLIC_URL}/vigilante`)
            }}
            className="animate__animated animate__fadeIn"
            size="large"
            sx={styles.Button}
            variant="contained"
            startIcon={<SportsMotorsportsIcon />}
          >
            Vigilante
          </Button>
        </Stack>
      </Box>
    </Container>
  )
}

export default Splash
