import Box from '@mui/material/Box'
import Card from '@mui/material/Card'

import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './styles'

const CardBasic = (props) => {
  return (
    <Card elevation={5} sx={styles.Card}>
      <Box sx={styles.Box}>
        <Box
          component="img"
          alt="image"
          src={props.link}
          sx={{ height: '100%', width: '100%' }}
        />
      </Box>
    </Card>
  )
}

CardBasic.propTypes = {
  link: PropTypes.string.isRequired
}

export default CardBasic
