export const Card = {
  margin: '1rem',
  color: '#FFFFFF',
  backgroundImage: 'linear-gradient(to left, #70B3F1, #4577F8)',
  display: 'flex',
  padding: 0,
  width: '95%'
}

export const Box = {
  display: 'flex',
  width: '100%',
  height: '180px',
  color: '#FFFFFF'
}
