export const Container = {
  width: '100%'
}

export const Box = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '3px 15px',
  bgcolor: '#0858DD'
}

export const Header = {
  display: 'flex',
  flexDirection: 'row'
}
