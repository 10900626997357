import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './styles'
import AccountMenu from '../avatar'

const ButtonAppBar = (props) => {
  return (
    <Box sx={styles.Container}>
      <Box sx={styles.Box}>
        <Box sx={styles.Header}>
          <Typography color="#ffffff" variant="h6" component="div">
            Visão
          </Typography>
          {/* <Typography sx={{ fontSize: '8px', marginTop: '5px' }} color="#ffffff" component="div">
          ®
          </Typography> */}
        </Box>
        <AccountMenu on={props.on} invisible={props.invisible} perfil={props.perfil} />
      </Box>
    </Box>
  )
}

ButtonAppBar.propTypes = {
  on: PropTypes.bool,
  invisible: PropTypes.bool,
  perfil: PropTypes.bool
}

ButtonAppBar.defaultProps = {
  on: true,
  invisible: true,
  perfil: true
}

export default ButtonAppBar
