export const Container = {
  display: 'flex',
  height: '100vh',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: 0
}

export const Box = {
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
}

export const Background = {
  position: 'relative',
  marginTop: '80px',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  borderRadius: '8px',
  marginBottom: '3rem'
}

export const BoxApp = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center'
}

export const BoxPlan = {
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'start',
  flexDirection: 'column',
  bgcolor: 'rgba(74, 168, 255, 0.13)',
  width: '80%',
  height: '350px',
  position: 'absolute',
  zIndex: '-1',
  padding: '.5rem .5rem ',
  boxSizing: 'border-box',
  top: -65
}

export const BoxSlide = {
  width: '90%'
}

export const BoxDialog = {
  padding: '1rem',
  display: 'flex',
  height: '40vh',
  width: '75vw',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly'
}
